import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {getUserCart} from 'services/InventoryService';
import config from 'config/config';
import './Cart.css';
import { Button } from "primereact/button";
import {formatNumberToCurrency} from 'utils/formatUtils';
import { addToCart, removeUserCartProduct } from "services/InventoryService";
import { Toast } from "primereact/toast";
import {useNavigate} from 'react-router-dom';
import { generateKey } from "utils/generatorUtils";
import debounce from 'lodash.debounce';
import  HeaderControl  from "components/header-control/HeaderControl";
import ImageLoader from "components/shared/ImageLoader";
import { Dialog } from 'primereact/dialog';
import { CiLocationOn } from "react-icons/ci";
import { getOrderSummary, placeOrder } from "services/OrderService";
import { buildUserAddress } from "utils/userDataUtils";
import Loader from "components/shared/Loader";
import { PrimeIcons } from 'primereact/api';
import {emitSocketEvent} from 'utils/SocketUtils';
import { useSocket } from 'providers/SocketContext';
import SocketEndpoints from 'socket/SocketEndpoint';
import CartAction from "enums/CartAction";
import { VariableSizeList as List } from 'react-window';

const CartProduct = ({cartProductData, setCartProducts, removeCartProduct, targetProduct}) => {


    const productDetails = cartProductData.productDetails;
    const isTargetProduct = targetProduct && targetProduct === productDetails.ProductId;
    const className = isTargetProduct? "cart_product target_cart_product" : "cart_product card";
    const imageUrl = `${config.serverUrl}/api/image/${productDetails.ProductId}`;
    const [cartQuantity, setCartQuantity] = useState(cartProductData.Quantity);
    const updateTimer = 250;
    const socket = useSocket();
    const handleQuantityChange = useCallback(debounce(async (newQuantity) => {
        // Your logic to send a POST request to update the server
            const newCartProductData = {ProductId: productDetails.ProductId, Quantity: newQuantity};
            emitSocketEvent(socket, SocketEndpoints.Inventory.update_user_cart, newCartProductData);
            
        
    }, updateTimer), [])
      

    const handleIncrement = async () => {
      const newQuantity = cartQuantity + 1;
      setCartQuantity(newQuantity);
      setCartProducts((prevCartProducts) => {
            return prevCartProducts.map((product) => {
                if (product.ProductId === productDetails.ProductId ) {
                return {...product, Quantity: newQuantity};
                } else {
                return product;
                }
            });
        });
      handleQuantityChange(newQuantity);

    };
  
    const handleDecrement = async () => {
      if (cartQuantity > 1) {
        const newQuantity = cartQuantity - 1;
        setCartQuantity(newQuantity);
        setCartProducts((prevCartProducts) => {
            return prevCartProducts.map((product) => {
                if (product.ProductId === productDetails.ProductId ) {
                return {...product, Quantity: newQuantity};
                } else {
                return product;
                }
            });
        });
        handleQuantityChange(newQuantity);

      }
    };

    


    return (
        <div className={className}>
            <div className="cart_product_details">
                <div className="cart_product_first_row">
                    <div className="cart_product_image_container">
                        <ImageLoader imageUrl={imageUrl} />
                    </div>
                    <div className="cart_product_info">
                        <div className="cart_product_name_text">{productDetails.ProductName}</div>
                        <div className="cart_product_units">
                            <div>{cartProductData.productDetails.UOM} </div>
                        </div>
                    </div>
                </div>
                <div className="cart_product_second_row">
                    <div className="cart_product_price">₱{productDetails.Price}</div>
                    <div className='quantity_control_child'>
                            <div className="control_button_container">
                                <button className='quantity_control_button' onClick={() => {handleDecrement()}}><i className={PrimeIcons.MINUS + " quantity_control_icons"}></i></button>
                            </div>
                            <span className='quantity_control_quantity' >{cartQuantity}</span>
                            <div className="control_button_container">
                                <button className='quantity_control_button' onClick={() => {handleIncrement()}}><i className={PrimeIcons.PLUS + " quantity_control_icons"}></i></button>
                            </div>
                    </div>
                </div>
                <div className="cart_product_remove_button_container">
                    <button className="cart_product_remove_button" onClick={() => {removeCartProduct(productDetails.ProductId, cartProductData.PriceType )}}>
                        <i className={PrimeIcons.TIMES_CIRCLE + " quantity_control_icons"}></i>
                    </button>
                </div>
            </div>
        </div>
    )


}

const Cart = ({handleCloseCart, targetProduct, action}) => {

    const [cartDisabled, setCartDisabled] = useState(false);

    const [cartProducts, setCartProducts] = useState([]);
    const [totalCartPrice, setTotalCartPrice] = useState(0);
    const [checkOutSummaryVisible, setCheckOutSummaryVisible] = useState(false);
    const toast = useRef(null);

    const [result, setResult] = useState(null);
    const navigate = useNavigate();

    const handleCloseEvent = () => {
        handleCloseCart(true, targetProduct);
    }

    useEffect(() => {
        const addToCartProduct = async (product) => {
            const cartData = {
                ProductId: product,
                Quantity: 1
            }
            
            const addToCartResult = await addToCart(cartData);
            return addToCartResult;
    
        }

        const performAddToCart = async () => {
            if (targetProduct) {
                try {
                    const addToCartResult = await addToCartProduct(targetProduct);
                    setResult({success: addToCartResult.success});
                } catch (error) {
                    console.error("Error adding to cart:", error);
                }
            }
        };
        if (action == CartAction.ADD) {
            performAddToCart();
        } else if (action == CartAction.MODIFY) {
            setResult({success: true});
        } else {
            setResult({success: true});
        }
    }, []);

    useEffect(() => {

        const fetchCartProducts = async (action, productId) => {
            if (action === CartAction.MODIFY || action === CartAction.ADD) {
                try {
                    const cartProductResult = await getUserCart();
                    const cart = [...cartProductResult.cart]; // Create a shallow copy of the cart to avoid mutating the state directly
            
                    // Find the index of the target product
                    const targetProductIndex = cart.findIndex((product) => product.ProductId === productId);
            
                    // Ensure the product exists in the cart
                    if (targetProductIndex !== -1) {
                        // Remove the target product from its original position
                        const [targetProduct] = cart.splice(targetProductIndex, 1);
            
                        // Insert the target product at the beginning of the cart
                        cart.unshift(targetProduct);
            
                        // Update the state with the modified cart
                        setCartProducts(cart);
                    } else {
                        console.log("Product not found in the cart");
                    }
                } catch (error) {
                    console.log("Error fetching cart products: ", error);
                    setCartProducts([]); // Reset the cart on error
                }
                return;
            }            

            try {
                const cartProductResult = await getUserCart();
                setCartProducts(cartProductResult.cart);
            } catch (error) {
                setCartProducts([]);
            }
        }
        


        fetchCartProducts(action, targetProduct);

    }, [result]);

    useEffect(() => {
        const calculateTotal = () => {
            let total = 0;
            cartProducts.forEach((product) => {
                    total += product.productDetails.Price * product.Quantity;
            });

            setTotalCartPrice(total);
        }

        calculateTotal();

    }, [cartProducts]);

    const removeCartProduct = async (productId, priceType) => {
        const removeCartProductResult = await removeUserCartProduct({ProductId: productId});

        if (removeCartProductResult.success) {
            setCartProducts((prevCartProducts) => {
                return prevCartProducts.filter((product) => product.ProductId !== productId);
            });
            
        } else {
            toast.current.show({severity: 'error', summary: 'Error', detail: 'Unable to remove product from cart', life: 1000});
        }
    }

    const handlePlaceOrder = async () => {
        setCheckOutSummaryVisible(false);
        setCartDisabled(true);

        const placeOrderResult = await placeOrder();
        if (placeOrderResult) {
            toast.current.show({severity: 'success', summary: 'Success', detail: 'Order placed successfully', life: 3000});
            setCartProducts([]);
            setTotalCartPrice(0);
            setCartDisabled(false);
        } else {
            toast.current.show({severity: 'error', summary: 'Error', detail: 'Unable to place order', life: 3000});
            setCartDisabled(false);
        }

    }


    const OrderSummary = ({handleSubmit}) => {
        const [totalProducts, setTotalProducts] = useState(0);
        const [totalItems, setTotalItems] = useState(0);

        const [cartSubTotal, setCartSubTotal] = useState(0);
        const [discount, setDiscount] = useState(0);
        const [loyaltyPoints, setLoyaltyPoints] = useState(0);
        const [shippingCost, setShippingCost] = useState(0);

        const [deliveryAddress, setDeliveryAddress] = useState("");
        const [finalPrice, setFinalPrice] = useState(0);

        useEffect(() => {
            const fetchFinalCart = async () => {
                try {
                    const orderSummary = await getOrderSummary();
                    
                    setTotalProducts(orderSummary.totalProducts);
                    setTotalItems(orderSummary.totalItems);
                    setCartSubTotal(orderSummary.cartSubTotal);
                    setDiscount(orderSummary.discount);
                    setLoyaltyPoints(orderSummary.loyaltyPoints);
                    setShippingCost(orderSummary.shippingCost);

                    setDeliveryAddress(buildUserAddress(orderSummary.address));
                    setFinalPrice(orderSummary.finalPrice);

                } catch (error) {

                }
            }

            fetchFinalCart();
            
        }, []);


        return (
            <div className="order_summary_content">
                <div className="order_summary_info">
                    <div className="order_delivery_address">
                        <CiLocationOn className="order_summary_icons" />
                        <div className="order_delivery_address_text">{deliveryAddress}</div>
                    </div>
                    <div className="order_summary_details">
                        <div>Total Products</div>
                        <div className="summary_total">{totalProducts}</div>
                        <div>Total Items</div>
                        <div className="summary_total">{totalItems}</div>
                    </div>

                    <div className="order_calculation">
                        <div className="order_subtotal">Subtotal</div>
                        <div className="summary_total">{formatNumberToCurrency(cartSubTotal)}</div>
                        <div className="order_subtotal">Discount</div>
                        <div className="summary_total">- {formatNumberToCurrency(discount)}</div>
                        <div className="order_subtotal">Loyalty</div>
                        <div className="summary_total">- {formatNumberToCurrency(loyaltyPoints)}</div>
                        <div className="order_subtotal">Delivery fee</div>
                        <div className="summary_total">+ {formatNumberToCurrency(shippingCost)}</div>
                    </div>
                    <div className="order_total">
                        <div className="order_total_header">Total</div>
                        <div className="summary_total final_price">{formatNumberToCurrency(finalPrice)}</div>
                    </div>

                </div>
                <div className="order_summary_controls">
                    <div className="order_summary_buttons">
                        <Button label="Place Order" className="checkout_confirm_button" onClick={() => handleSubmit()} />
                    </div>

                </div>
            </div>
        )
    }

    //fixed size list
    const getItemSize = () => {
        return 120;
    }

    const Row = ({ index, style }) => {
        const product = cartProducts[index];
        const productDetails = product.productDetails

        const productId = productDetails.ProductId;
        const generatedKey = generateKey();
        return (
            <div className='cart_prodct' style={style}>
                <CartProduct key={generatedKey} cartProductData={product} setCartProducts={setCartProducts} removeCartProduct={removeCartProduct} targetProduct={targetProduct} />
            </div>
        )

    }

    return(

        <div className="cart_content" >
            <Loader isLoading={cartDisabled} />
            <HeaderControl title="Cart" handleCloseEvent={handleCloseEvent}></HeaderControl>
            <Toast ref={toast} />
            <Dialog header={<div>Order Summary</div>} style={{ width: '80vw', height:'80vh' }} visible={checkOutSummaryVisible} className="checkout_summary_dialog" onHide={() => setCheckOutSummaryVisible(false)} >
                <OrderSummary handleSubmit={handlePlaceOrder}/>
            </Dialog>

            <div className="cart_container">
                    {/* {
                        cartProducts.map(  (product, index) => {
                            const generatedKey = generateKey()
                            return <CartProduct key={generatedKey} cartProductData={product} setCartProducts={setCartProducts} removeCartProduct={removeCartProduct} targetProduct={targetProduct} />
                        })
                    
                    } */}
                    <List
                        height={window.innerHeight * 0.82}
                        itemCount={cartProducts.length}
                        items={cartProducts}
                        itemSize={getItemSize}
                        width={window.innerWidth}
                    >
                        {Row}
                    </List>
                    {
                    //if cart is empty
                        cartProducts.length === 0 && <div className="empty_cart"><div className="empty_cart_text">Your cart is empty.</div> <div className="empty_cart_button_container"><Button className="empty_cart_button" onClick={()=>{handleCloseCart();navigate("/dashboard/shop")}}>Add Products</Button></div></div>
                    }
            </div>
            <div className="checkout_container">
                <div className="checkout_payment_options_header">Payment Options: </div>
                <div className="checkout_payment_options">Cash On Delivery </div>
                <div className="checkout_controls">
                    <div className="checkout_total_content">
                        <div className="checkout_total_summary">{formatNumberToCurrency(totalCartPrice)}</div>
                        <div className="checkout_total_header">Total</div>
                    </div>
                    <div className="checkout_button"><Button onClick={()=>{setCheckOutSummaryVisible(true);}}>Checkout ({cartProducts.length})</Button></div>

                </div>
            </div>
        </div>
        )

}

export default Cart;