
// const config = {
//     server:"192.168.100.160:5555",
//     serverUrl: "http://192.168.100.160:5555"
//   };
  
// export default config;
  
//for deployment use the one below

const config = {
    serverUrl: window.location.protocol + "//" + window.location.host,
    server: window.location.host
  };
  
  export default config;
  