import React, { useState, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Tree } from 'primereact/tree';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './Shop.css';  // Importing the CSS file
import { DataScroller } from 'primereact/datascroller';
import ProductListController from "components/product-list-controller/ProductListController";

import { getInventoryProducts, getAllProducts } from 'services/InventoryService';

export default function Shop() {
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const [visible, setVisible] = useState(false); // State to control sidebar visibility
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(''); // State for debounced search term  
    const [products, setProducts] = useState([]); // State for products

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productList = await getAllProducts();
                setProducts(productList);
            } catch (error) {
                console.error('Error fetching products:', error);
                setProducts([]);
            }
        };
        fetchProducts();

    }, []);

    const onSearchChange = (e) => {
        setValue(e.value);
        setDebouncedSearchTerm(e.value);
    };

    // Sample categories
    const [nodes, setNodes] = useState([
        {
            key: "0",
            label: "Electronics",
            children: [
                { key: "0-0", label: "Laptops" },
                { key: "0-1", label: "Smartphones" }
            ]
        },
        {
            key: "1",
            label: "Books",
            children: [
                { key: "1-0", label: "Fiction" },
                { key: "1-1", label: "Non-Fiction" }
            ]
        },
        {
            key: "2",
            label: "Clothing",
            children: [
                { key: "2-0", label: "Men" },
                { key: "2-1", label: "Women" }
            ]
        },
        { key: "3", label: "Home" },
        { key: "4", label: "Toys" }
    ]);

    const [selectedKey, setSelectedKey] = useState(null);

    const onCategorySelect = (e) => {
        setSelectedKey(e.value);
    };

    const nodeTemplate = (node, options) => {
        let style;
        if (selectedKey === node.key) {
            style = { color: node.children ? 'sagaBlue' : 'crimson' };
        }

        return (
            <span style={style}>
                {node.label}
            </span>
        );
    };

    return (
        <div className="main-shop-content">

        <div className="product_container">
            <ProductListController />
        </div>

        </div>
    );
}
