
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import './OrderList.css';
import OrderStatus from 'enums/OrderStatus';
import {getOrders} from 'services/OrderService';
import {formatDate, formatNumberToCurrency} from 'utils/formatUtils';
import { Dialog } from 'primereact/dialog';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';


const OrderDisplay = ({orderData, orderType, itemListViewHandler}) => {

    return (
        <div className="order_display card">
            <div className="order_display_header">
                <div className="order_display_header_title" >{orderData.OrderId}</div>
                <div className="order_display_header_status">{orderType}</div>
            </div>
            <div className='order_display_body'>
                <div className="order_display_date">Order Date: {formatDate(orderData.PlacedDate)}</div>
                <div className="order_display_items">
                    <div>Total Items: {orderData.OrderSummary.totalItems}</div>
                    <div><Button className='view_items_button' onClick={() => itemListViewHandler(orderData)}>View Items</Button></div>
                </div>
                <div className="order_display_total">
                    <div>Total</div>
                    <div className="order_display_total_amount">{formatNumberToCurrency(orderData.OrderSummary.finalPrice)}</div>
                </div>
            </div>
        </div>
    )
}

const OrderItemDisplay = ({orderData}) => {

    const [cartData, setCartData] = useState(orderData.Cart);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        barcode: { value: null, matchMode: FilterMatchMode.CUSTOM },
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
    
        setFilters(_filters);
    };

    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';

        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };

    const header = renderHeader()

    const PriceTemplate = (rowData) => {

        return (
            <div>
                {formatNumberToCurrency(rowData.productDetails.Price)}
            </div>
        )
    }

    const UnitOfMeasureTemplate = (rowData) => {

        return (
            <div>
                {rowData.productDetails.UOM}
            </div>
        )
    }

    
    const SubTotalTemplate = (rowData) => {

        const returnSubTotal = () => {

            return rowData.productDetails.Price * rowData.Quantity
        }
        

        return (
            <div>
                {formatNumberToCurrency(returnSubTotal())}
            </div>
        )
    }

    

    return (
        <div className="order_item_display">
            <div>Order: {orderData.OrderId}</div>
            { orderData && <DataTable value={cartData} tableStyle={{ width: '100%', fontSize: '9px'}} 
                    header={header}
                    dataKey={cartData} filterDisplay="row" emptyMessage="No product found." 
                    filters={filters} onFilter={(e) => setFilters(e.filters)}
                    scrollable scrollHeight={window.innerHeight * 0.6} virtualScrollerOptions={{ itemSize: 100 }}
                >

                    <Column field="productDetails.ProductName" header="Product Name" sortable />
                    <Column body={UnitOfMeasureTemplate} header="UOM" />
                    <Column body={PriceTemplate} header="Price Bought" />
                    <Column field="Quantity" header="Quantity" sortable/>
                    <Column body={SubTotalTemplate} header="SubTotal"/>

                </DataTable>
                }
        </div>
    )
}

const OrderList = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const items = [
        { label: 'Pending',     orderType: OrderStatus.PENDING },
        { label: 'Processing',  orderType: OrderStatus.PROCESSING},
        { label: 'To Receive',  orderType: OrderStatus.IN_TRANSIT},
        { label: 'Completed' ,  orderType: OrderStatus.COMPLETED},
        { label: 'Cancelled',   orderType: OrderStatus.CANCELLED},
    ];

    const [orders, setOrders] = useState([]);

    useEffect(() => {

        const fetchOrders = async () => {
            const orderType = items[activeIndex].orderType;
            const result = await getOrders(orderType);

            if(result.success) {
                setOrders(result.orders);
            }
            console.log(result.orders)
        }

        fetchOrders();
        
    }, [activeIndex]);

    const [viewItemDialogOpen, setViewItemDialogOpen] = useState(false);
    const [selectedOrderData, setSelectedOrderData] = useState(null);

    const itemListViewHandler = (orderData) => {
        console.log(orderData);
        setSelectedOrderData(orderData);
        setViewItemDialogOpen(true);
    }

    return (
        <div className="orderlist_content">
            <Dialog header={<div>View Items</div>} visible={viewItemDialogOpen} onHide={() => {setViewItemDialogOpen(false)}} className='view_items_dialog' >
                <OrderItemDisplay orderData={selectedOrderData} />
            </Dialog>
            <div className="orderlist_tab_menu_parent">
                <div >
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="orderlist_p_tab_menu" />
                </div>
            </div>
            <div className="orderlist_container">
                    {orders.map((order, index) => {

                        return (
                            <OrderDisplay key={index} orderData={order} orderType={items[activeIndex].label} itemListViewHandler={itemListViewHandler}/>
                        )

                    })}

            </div>
        </div>
    )
}

export default OrderList;