
//get is the request and recieve is the response

const SocketEndpoints = {
    Inventory:{
        query_products_by_category:{
            REQUEST: 'request_query_products_by_category',
            RESPONSE: 'response_query_products_by_category'
        },
        query_products_by_search_string:{
            REQUEST: 'request_query_products_by_search_string',
            RESPONSE: 'response_query_products_by_search_string'
        },
        update_user_cart:{
            REQUEST: 'request_update_user_cart',
            RESPONSE: 'response_update_user_cart'
        }
    }

};

export default SocketEndpoints;