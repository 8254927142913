import { json } from 'react-router-dom';
import config from '../config/config';
import {getToken} from '../utils/tokenUtils'

const getOrderSummary = async () => {
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const result = {success: false, message:"Failed to retrieve products", orderSummary: {}}
        const url = `${config.serverUrl}/api/get-order-summary`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            return responseJson.orderSummary;
        } else {
            return null; // Handle error or not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }
}

const placeOrder = async () => {
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const result = {success: false, message:"Failed to retrieve products", orderSummary: {}}
        const url = `${config.serverUrl}/api/place-order`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, 
            },
            body: JSON.stringify({}) 
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            return responseJson.orderId;
        } else {
            return null; // Handle error or not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }
}

const getOrders = async (orderState) => {
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const result = {success: false, message:"Failed to retrieve orders", orders: {}}
        const url = `${config.serverUrl}/api/get-client-order/${orderState}`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });

        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
           result.success = true;
           result.orders = responseJson.orders;
        }

        return result;
    } catch (error) {
      throw error
    }
}


export {getOrderSummary, placeOrder, getOrders};