import React, { useEffect, useCallback } from 'react';
import { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './ProductListController.css'
import { getProductCategories , getProductsByCategory, getProductById} from 'services/InventoryService';
import {Ripple} from 'primereact/ripple';
import config from 'config/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dialog } from 'primereact/dialog';
import {Image} from 'primereact/image';
import { Toast } from 'primereact/toast';
import { generateKey } from 'utils/generatorUtils';
import AddToCartController from 'components/add-to-cart-controller/AddToCartController';
import ImageLoader from 'components/shared/ImageLoader';
import { useSocket } from 'providers/SocketContext';
import  SocketEndpoints  from 'socket/SocketEndpoint';
import { sendSocketRequest } from 'utils/SocketUtils';
import { VariableSizeList as List } from 'react-window';
import { set } from 'lodash';
import Cart from 'components/cart/Cart';
import CartAction from 'enums/CartAction';
import { formatNumberToCurrency } from 'utils/formatUtils';
import ProductDisplay from 'components/product-display/ProductDisplay';



const ProductListController = () => {
    // State to track the active category
    const [categories, setCategories] = useState([]); // Sample categories [1, 2, 3, 4, 5
    const [activeCategory, setActiveCategory] = useState(null);
    const [products, setProducts] = useState([]); // State for products
    const [displayData, setDisplayData] = useState([]); // State for products
    const [addToCartDialogVisible, setAddToCartDialogVisible] = useState(false);
    const [targetProduct, setTargetProduct] = useState(null); // State for products
    
    const toast = useRef(null);
    const socket = useSocket();

    //virtual list related variables
    const [scrollOffset, setScrollOffset] = useState(0);

    // Cart related variables
    const [isCartVisible, setIsCartVisible] = useState(false);
    const [cartAction, setCartAction] = useState(null);

    const handleAddToCart = async (productId, listRef, currentScroll) => {

        setTargetProduct(productId)
        setScrollOffset(currentScroll);
        setCartAction(CartAction.ADD);
        setIsCartVisible(true);

    }

    const handleEditCart = async (productId, listRef, currentScroll) => {
            
        setTargetProduct(productId)
        setScrollOffset(currentScroll);
        setCartAction(CartAction.MODIFY);
        setIsCartVisible(true);
    }

    const handleCloseCart = (success, productId) => {
        setIsCartVisible(false);
        sendSocketRequest(socket, SocketEndpoints.Inventory.query_products_by_category, {Category: activeCategory.Name}, handleProductData );

    }

    useEffect(() => {

        // socket.on(SocketEndpoints.Inventory.query_products_by_category.RESPONSE, (data) => {
        //     if(data.success){
        //         setProducts(data.products);
        //     }
        // });

    }, []);

    // useEffect(() => {
    //     if (listRef.current && addToCartDialogVisible) {
    //         listRef.current.scrollTo(scrollTop);
    //         console.log(scrollTop);
    //     }
    // }, [scrollTop, addToCartDialogVisible]);

    useEffect(() => {

        const fetchCategories = async () => {
            try {
                // Fetch categories from API
                const categories = await getProductCategories();
                setCategories(categories);
                setActiveCategory(categories[0]);
                loadCategoryProducts(categories[0]);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setCategories([]);
            }
        }

        fetchCategories();

    }, []);

    

    // Handle Click function to set active category
    const handleCategoryClick = (category) => {
        setActiveCategory(category);
        loadCategoryProducts(category);
    };

    useEffect(() => {
        const subCategories = [];
        products.forEach((product) => {
            if (!subCategories.includes(product.SubCategoryName)) {
                subCategories.push(product.SubCategoryName);
            }
        });
    
        const newDisplayData = subCategories.map((subCategory) => {
            const subCategoryProducts = products.filter((product) => product.SubCategoryName === subCategory);
            return { subCategoryName: subCategory, products: subCategoryProducts };
        });
    
        setDisplayData(newDisplayData);
    }, [products]);

    const DisplayComponent = ({ data, scrollOffset }) => {
        const listRef = useRef(null);       
        // Flatten the data into a single array of items
        const [items, setItems] = useState(data.flatMap((subCategory) => [
            { type: 'subcategory', ...subCategory },
            ...subCategory.products.map(product => ({ type: 'product', ...product }))
        ]));
    
        // Determine the item size for each index
        const getItemSize = index => {
            const item = items[index];
            if (item.type === 'subcategory') {
                return 60; // Adjust height for subcategory headers
            }
            return 150; // Adjust height for product items
        };
    
        // Render each item
        const Row = ({ index, style }) => {
            const item = items[index];
            if (item.type === 'subcategory') {
                return (
                    <div className='subCategoryContainer' style={style}>
                        <h6 className='subCategoryHeader'>{item.subCategoryName}</h6>
                    </div>
                );
            }
            return (
                <div className='productListContainer' style={style}>
                    <ProductDisplay productData={item} listControl={listRef} handleAddToCart={handleAddToCart} handleEditCart={handleEditCart} />
                </div>
            );
        };
        
        return (
            <List
                height={window.innerHeight}
                itemCount={items.length}
                itemSize={getItemSize}
                width={window.innerWidth * 0.7}
                ref={listRef}
                initialScrollOffset={scrollOffset}            >
                {Row}
            </List>
        );
    };

    const handleProductData = (data) => {
        if(data.success){
            setProducts(data.products);
        }
    }
    
    const loadCategoryProducts = async (category) => {
        try {
            setScrollOffset(0);
            sendSocketRequest(socket, SocketEndpoints.Inventory.query_products_by_category, {Category: category.Name}, handleProductData );
            // const products = await getProductsByCategory(category);
            // setProducts(products);
            
        } catch (error) {
            // console.error('Error fetching products:', error);
            // setProducts([]);
        }
    }

    const handleAddToCartResult = (productName, addToCartResult) => {
        const cartSuccessMessage = productName + ' added to cart';
        
        toast.current.show({ severity: addToCartResult && addToCartResult.success ? 'success' : 'error', summary: addToCartResult && addToCartResult.success ? 'Success' : 'Failed', detail: addToCartResult.message, life: 3000 });

        setAddToCartDialogVisible(false);
    }


    return (
        <div className='product_list_controller_container'>
            <Toast ref={toast} />
            <div className="categoryNav">
                {/* Map buttons dynamically if you have an array of categories, else statically */}
                {categories.map((category) => (
                    <button 
                        key={category.CategoryId} 
                        className={`categoryButton ${activeCategory === category ? 'active' : ''}`}
                        onClick={() => handleCategoryClick(category)}
                    >
                       {category.Name}
                    </button>
                ))}
            </div>
            <div className="productList">
                <h3 className='selectedCategoryHeader'>{activeCategory != null? activeCategory.Name: 'No category selected'}</h3>

                <div className='productContainer'>
                    <DisplayComponent data={products} scrollOffset={scrollOffset} />
                </div>
            </div>

            {isCartVisible && <div className="dashboard_cart">
                <Cart className="dashboard_cart" handleCloseCart={handleCloseCart} targetProduct={targetProduct} action={cartAction}  />
            </div>}

        </div>
    );
};

export default ProductListController;
