
import { getToken } from "./tokenUtils"
import SocketEndpoints from '../socket/SocketEndpoint'
const emitSocketEvent = (socket, event, data) => {
    data.AuthToken = getToken();
    socket.emit(event.REQUEST, data);

};

const sendSocketRequest = (socket, event, data, handleResponse) => {
    data.AuthToken = getToken();
    socket.emit(event.REQUEST, data);

    if (socket.listeners(event.RESPONSE).length === 0) {
        socket.once(event.RESPONSE, (data) => {
            handleResponse(data)
        });
    }


};



export { emitSocketEvent, sendSocketRequest}