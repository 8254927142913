

import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./HeaderControl.css"


const HeaderControl = ({title, handleCloseEvent}) => {

    const navigate = useNavigate();

    const handleBack = () => {
        if (handleCloseEvent) {
            handleCloseEvent();
            return
        }
        navigate(-1);
    }
    return (
        <div className="header_control">
            <div className='header_content'> 
                <button className="header_back_button" onClick={()=>{handleBack()}}><i className="pi pi-arrow-circle-left"></i></button>
                <h1>{title}</h1>
            </div>
        </div>
    )

}

export default HeaderControl;