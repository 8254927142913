import {useState, useEffect, useRef} from 'react'
import { useParams, useNavigate } from "react-router-dom";
import HeaderControl from "components/header-control/HeaderControl";
import { searchProducts } from 'services/InventoryService';
import config from 'config/config';
import { Button } from 'primereact/button';
import { generateKey } from 'utils/generatorUtils';
import { Dialog } from 'primereact/dialog';
import AddToCartController from 'components/add-to-cart-controller/AddToCartController';
import { Toast } from 'primereact/toast';
import './ProductSearch.css'
import { Chip } from 'primereact/chip';
import ImageComponent from 'components/shared/ImageLoader';
import {sendSocketRequest} from 'utils/SocketUtils';
import { useSocket } from 'providers/SocketContext';
import SocketEndpoints from 'socket/SocketEndpoint';
import { VariableSizeList as List } from 'react-window';
import ProductDisplay from 'components/product-display/ProductDisplay';
import Cart from 'components/cart/Cart';
import CartAction from 'enums/CartAction';
import { set } from 'lodash';


const ProductSearch = () => {
    const { product_search_string } = useParams();
    const navigate = useNavigate();
    if (product_search_string === undefined) {
        navigate("/dashboard/home");
    }

    const socket = useSocket();

    const [searchedProducts, setSearchedProducts] = useState([])
    const [addToCartDialogVisible, setAddToCartDialogVisible] = useState(false);
    const [targetAddToCartProductId, setTargetAddToCartProductId] = useState(null);
    const [isCartVisible, setIsCartVisible] = useState(false);
    const [targetProduct, setTargetProduct] = useState(null);
    const [cartAction, setCartAction] = useState(null);
    
    const toast = useRef(null);

    const [scrollOffset, setScrollOffset] = useState(0);

    const handleFetchedProducts = (data) => {
        if (data.success) {
            setSearchedProducts(data.products);
        } else {
            setSearchedProducts([]);
        }
    }

    useEffect(()=>{
        const fetchSearchedProducts = async () => {
            sendSocketRequest(socket, SocketEndpoints.Inventory.query_products_by_search_string, {SearchString: product_search_string}, handleFetchedProducts);
            // const result = await searchProducts(product_search_string);
            // setSearchedProducts(result.products);
        }
        
        fetchSearchedProducts();
    }, [])

    const handleAddToCart = async (productId, listRef, currentScroll) => {
        setTargetProduct(productId)
        setScrollOffset(currentScroll);
        setCartAction(CartAction.ADD);
        setIsCartVisible(true);
    }

    const handleEditCart = async (productId, listRef, currentScroll) => {
        setTargetProduct(productId)
        setScrollOffset(currentScroll);
        setCartAction(CartAction.MODIFY);
        setIsCartVisible(true);
    }

    const handleCloseCart = (success, productId) => {
        setIsCartVisible(false);
        sendSocketRequest(socket, SocketEndpoints.Inventory.query_products_by_search_string, {SearchString: product_search_string}, handleFetchedProducts);
    }

    const DisplayComponent = ({ data, scrollOffset }) => {
        const listRef = useRef(null);       
        // Flatten the data into a single array of items
        const [items, setItems] = useState(data);
    
        // Determine the item size for each index
        const getItemSize = index => {
            console.log(data)
            return 150; // Adjust height for product items
        };
    
        // Render each item
        const Row = ({ index, style }) => {
            const item = items[index];
            if (item.type === 'subcategory') {
                return (
                    <div className='subCategoryContainer' style={style}>
                        <h6 className='subCategoryHeader'>{item.subCategoryName}</h6>
                    </div>
                );
            }
            return (
                <div className='productListContainer' style={style}>
                    <ProductDisplay productData={item} listControl={listRef} handleAddToCart={handleAddToCart} handleEditCart={handleEditCart} />
                </div>
            );
        };
        
        return (

            <List
                height={window.innerHeight}
                itemCount={items.length}
                itemSize={getItemSize}
                width={window.innerWidth}
                ref={listRef}
                initialScrollOffset={scrollOffset}            >
                {Row}
            </List>
        );
    };

    return(
        <div>
        <div className="Product_Search_Control">
            <Toast ref={toast} />
            <HeaderControl title="Search Results"  />
            <div className="product_search_list">
                <DisplayComponent data={searchedProducts} scrollOffset={scrollOffset} />
            </div>
            {searchedProducts.length == 0 && <div className='empty_search'>Product <span className='empty_search_product_name'>{product_search_string}</span> not found</div>}

        </div>
        {isCartVisible && <div className="dashboard_cart">
            <Cart className="dashboard_cart" handleCloseCart={handleCloseCart} targetProduct={targetProduct} action={cartAction}  />
        </div>}
        </div>
    )
};

export default ProductSearch;