import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import  checkAuthentication from 'utils/clientUtils';
import { PrimeReactProvider } from 'primereact/api';
import Dashboard from 'components/dashboard/Dashboard';
import Shop from 'components/shop/Shop';
import Signup from 'components/sign-up/Signup';
import Login from 'components/login/Login';
import Cart from 'components/cart/Cart';
import ProductSearch from 'components/product-search/ProductSearch';
import { Dialog } from 'primereact/dialog';
import OrderList from 'components/order-list/OrderList';
import { SocketProvider, useSocket } from 'providers/SocketContext';
import { ThreeDots } from 'react-loader-spinner'
import './App.css';


function PrivateRoute() {
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const fetchAuthStatus = async () => {
            const isAuth = await checkAuthentication();
            setIsAuthenticated(isAuth);
        };
        
        fetchAuthStatus();
    }, []);

    // useEffect(() => {
    //     const setToFullScreen = () => {
    //       if (document.documentElement.requestFullscreen) {
    //         document.documentElement.requestFullscreen();
    //       } else if (document.documentElement.mozRequestFullScreen) { // Firefox
    //         document.documentElement.mozRequestFullScreen();
    //       } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
    //         document.documentElement.webkitRequestFullscreen();
    //       } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
    //         document.documentElement.msRequestFullscreen();
    //       }
    //     }

    //     setToFullScreen();
    // }, []);


    if (isAuthenticated === false) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    } 
    return <Outlet />; 
}

const DisconnectedNotice = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [isReconnecting, setIsReconnecting] = useState(true);

  useEffect(() => {
    let counter = 0;
    let maxCounter = 10;
    setInterval(() => {
      const isOnline = navigator.onLine;
      setIsOnline(isOnline);
      if(!isOnline || counter > maxCounter){
        setIsReconnecting(false);
        clearInterval();
      }
      counter++;
    },1000);


  },[])


  return (
    <div className='disconnected_content'>
      <div className="background_opacity">
        <h1>test</h1>
      </div>
      <div className="disconnected_notice">
        {isReconnecting && <div className='disconnected_header'>Reconnecting</div>}
        {isReconnecting && <div className='disconnected_loading'><ThreeDots visible={true} height="80" width="80" color="crimson" radius="9" ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/></div>}
        {!isReconnecting && <div className='disconnected_header'>Disconnected</div>}
        {!isReconnecting && <div >{!isOnline? "Please check internet connection.":"Server is too busy. Please try again later" }</div>}
      </div>
    </div>
  );

}

function App() {

  const [isServerConnected, setIsServerConnected] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(false);
  const socket = useSocket();

  useEffect(() => {

    if(window.location.pathname === "/login"){
      setIsLoginPage(true);
    }

    socket.on('connect', () => {
      setIsServerConnected(true)
    })

    socket.on('disconnect', () => {
      setIsServerConnected(false)
    })

    socket.on('client-no-auth', () => {

    })

  }, []);

  return (
    <PrimeReactProvider>
      <Dialog>
        
      </Dialog>
      <Router>
        <Routes>
          
          <Route element={<PrivateRoute/>}>
            <Route path="/dashboard" replace element={<Dashboard />}>
              <Route path="shop" element={<Shop/>} />
              <Route path="orders" element={<OrderList/>} />
            </Route>
            <Route path="/productsearch/:product_search_string" replace element={<ProductSearch />}></Route>
          </Route>
          <Route
            path="/"
            element={<Navigate to="/dashboard" />}
          />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="*" element={< Login/>} />
        </Routes>
      </Router>

       {(!isServerConnected) && (
        <DisconnectedNotice />
      )} 

    </PrimeReactProvider>

  );
}

export default App;
