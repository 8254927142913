
const toastLife = 3000;

const showSuccessToast = (toast, title, message) => {
    toast.current.show({ severity: 'success', summary: title, detail: message, life: toastLife });
}

const showWarnToast = (toast, title, message) => {
    toast.current.show({ severity: 'warn', summary: title, detail: message, life: toastLife });
}

const showErrorToast = (toast,title, message) => {
    toast.current.show({ severity: 'error', summary: title, detail: message, life: toastLife });
}   

export { showSuccessToast, showWarnToast, showErrorToast };