import React, { useEffect, useState } from 'react';
import './Loader.css';
import { ProgressSpinner } from 'primereact/progressspinner';
const Loader =  ({isLoading}) => {

    const [loaderClass, setLoaderClass] = useState("loader_visible hidden");

    useEffect(() => {
        if (isLoading) {
            setLoaderClass("loader_visible");
        } else {
            setLoaderClass("loader_visible hidden");
        }


    }, [isLoading]);

    return (
        <div className={loaderClass}>
             <ProgressSpinner className='loader_spinner' strokeWidth="4"/>
        </div>
    );
}

export default Loader;