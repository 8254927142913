

import config from '../config/config'

const registerUser = async (userDetails) => {
    const result = {success: false, message: "Failed to register user. Please contact support."};

    try {
        // No need for a token since this is a registration request
        const url = `${config.serverUrl}/signup`; // Adjust URL as per your API endpoint

        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request if needed
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userDetails),
        });

        const responseJson = await response.json();

        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success;
            result.message = responseJson.message;
            result.user = responseJson.user; // Assuming the API returns the registered user
            return result;
        } else {
            result.success = responseJson.success;
            result.message = responseJson.message;
            return result; // Return the server's response message
        }
    } catch (error) {
        console.error(error);
        result.success = false;
        result.message = "Failed to register user. Please contact support.";
        return result;
    }
}

const loginUser = async (userCredentials) => {
    const result = {success: false, message: "Failed to login user. Please contact support."};

    try {
        // No need for a token since this is a registration request
        const url = `${config.serverUrl}/login`; 

        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userCredentials),
        });

        const responseJson = await response.json();

        if (response.status === 200 && responseJson.success) {
            result.success = responseJson.success;
            result.message = responseJson.message;
            result.token = responseJson.token; 
            return result;
        } else {
            
            result.success = responseJson.success;
            result.message = responseJson.message;
            return result; // Return the server's response message
        }
    } catch (error) {
        console.error(error);
        result.success = false;
        result.message = "Failed to login user. Please contact support.";
        return result;
    }
}

export {registerUser, loginUser}