import { json } from 'react-router-dom';
import config from '../config/config';
import {getToken} from '../utils/tokenUtils'

const getInventoryProducts = async (searchQuery) => {
    try {
        const result = {success: false, message:"Failed to retrieve products", products: []}
        const url = `${config.serverUrl}/inventory/search-products?q=${encodeURIComponent(searchQuery)}`
        const response = await fetch(url, {
            method: 'GET'
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            return responseJson.products;
        } else {
            return null; // Handle error or not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }
}

const getAllProducts = async () => {
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const result = {success: false, message:"Failed to retrieve products", products: []}
        const url = `${config.serverUrl}/api/get-products`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });

        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            return responseJson.products;
        } else {
            return null; // Handle error or not authenticated
        }

    } catch (error) {

      throw error
    }
}

const getProductCategories = async () => {
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const result = {success: false, message:"Failed to retrieve products", products: []}
        const url = `${config.serverUrl}/api/get-product-categories`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            return responseJson.productCategories;
        } else {
            return null; // Handle error or not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }
}

const getProductsByCategory = async (category) => {
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const result = {success: false, message:"Failed to retrieve products", products: []}
        const url = `${config.serverUrl}/api/get-products-by-category/${category.Name}`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            return responseJson.products;
        } else {
            return null; // Handle error or not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }

}

const getProductById = async (productId) => {
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const result = {success: false, message:"Failed to retrieve products", products: []}
        const url = `${config.serverUrl}/api/get-product-by-id/${productId}`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            return responseJson.product;
        } else {
            return null; // Handle error or not authenticated
        }
    } catch (error) {
      console.error(error);
      throw error
    }

}

const addToCart = async (cartProduct) => {
    const result = {success: false, message:"Failed to add product to cart"}
    try {
        const token = getToken(); // Retrieve the token from localStorage
        
        const url = `${config.serverUrl}/api/add-to-cart`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
            body: JSON.stringify(cartProduct)
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = "Successfully added product to cart";
            result.newQuantity = responseJson.newQuantity;
        } else {
            result.success = false;
            result.message = responseJson.message;
        }
        return result;
    } catch (error) {
        //if error message contains "ERR_CONNECTION_REFUSED" then server is not running
        if (error.message.includes("ERR_CONNECTION_REFUSED")) {
            result.message = "Cannot connect to server. Please try again later";
        } else {
            result.message = "Failed to add product to cart. Please try again later"
        }
      result.success = false;
      return result;
    }
}

const getUserCart = async () => {
    const result = {success: false, message:"Failed to retrieve cart", cart: []}
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const url = `${config.serverUrl}/api/get-cart-products`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = "Successfully retrieved cart";
            result.cart = responseJson.cart;
            return result
        } else {
            return result; // Handle error or not authenticated
        }
    } catch (error) {
        if (error.message.includes("ERR_CONNECTION_REFUSED")) {
            result.message = "Cannot connect to server. Please try again later";
        } else {
            result.message = "Failed to add product to cart. Please try again later"
        }
      result.success = false;
      return result;
    }

}

const removeUserCartProduct = async (cartProduct) => {
    const result = {success: false, message:"Failed to remove product from cart"}
    try {
        const token = getToken(); // Retrieve the token from localStorage
        
        const url = `${config.serverUrl}/api/remove-cart-product`
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            },
            body: JSON.stringify(cartProduct)
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = "Successfully removed product from cart";
        } else {
            result.success = false;
            result.message = responseJson.message;
        }
        return result;
    } catch (error) {
        //if error message contains "ERR_CONNECTION_REFUSED" then server is not running
        if (error.message.includes("ERR_CONNECTION_REFUSED")) {
            result.message = "Cannot connect to server. Please try again later";
        } else {
            result.message = "Failed to remove product from cart. Please try again later"
        }
      result.success = false;
      return result;
    }

}

const searchProducts = async (searchString) => {
    const result = {success: false, message:"Failed to search product", products: []}
    try {
        const token = getToken(); // Retrieve the token from localStorage
        const url = `${config.serverUrl}/api/search-products/${searchString}`
        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Set the Authorization header with the token
            }
        });
        const responseJson = await response.json();
        if (response.status === 200 && responseJson.success) {
            result.success = true;
            result.message = "Successfully retrieved products";
            result.products = responseJson.products;
            return result
        } else {
            return result; // Handle error or not authenticated
        }
    } catch (error) {
        if (error.message.includes("ERR_CONNECTION_REFUSED")) {
            result.message = "Cannot connect to server. Please try again later";
        } else {
            result.message = "Failed to add product to cart. Please try again later"
        }
      result.success = false;
      return result;
    }
}

export { 
         getInventoryProducts, getAllProducts, getProductCategories,
         getProductsByCategory, getProductById, 
         getUserCart, addToCart, removeUserCartProduct, searchProducts 
        };