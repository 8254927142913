import{ Button } from 'primereact/button';
import config from 'config/config';
import ImageLoader from 'components/shared/ImageLoader';
import { formatNumberToCurrency } from 'utils/formatUtils';

const ProductDisplay = ({productData, listControl, handleAddToCart, handleEditCart}) => {
    const imageUrl = `${config.serverUrl}/api/image/${productData.ProductId}`;

    const editCart = async (productId) => {
        await handleEditCart(productId, listControl, listControl.current.state.scrollOffset);
    }

    const addToCart = async (productId) => {
        await handleAddToCart(productId, listControl, listControl.current.state.scrollOffset);

    }
    return (
        <div className='productCard'>
            <div className='imageContainer'>
                <ImageLoader imageUrl={imageUrl} />
            </div>
            <div className='productDetails'>
                <div className='productName'>{productData.ProductName}</div>

                <div className='productInfo'><span className="price">{formatNumberToCurrency(productData.Price)}</span> per {productData.UOM}</div>
                {productData.cartQuantity > 0 ? (
                    <Button 
                        className='editCartButton' 
                        icon='pi pi-fw pi-pencil' 
                        onClick={() => editCart(productData.ProductId)}
                    >
                        Edit Cart ({productData.cartQuantity})
                    </Button>
                    ) : (
                    <Button 
                        className='addToCartButton' 
                        icon='pi pi-fw pi-shopping-cart' 
                        onClick={() => addToCart(productData.ProductId)}
                    >
                        Add to Cart
                    </Button>
                )}
                
            </div>
        </div>
    );
}
export default ProductDisplay;