import React, { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Image } from 'primereact/image';
import './ImageLoader.css';

const ImageComponent = ({ imageUrl }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="image_loader" >
      {/* {loading && (
        <div className="spinner_container" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
         <ProgressSpinner className='progress_spinner' strokeWidth="4"/>
        </div>
      )} */}
      <Image
        width="100%"
        height="100%"
        className="image_element"
        src={imageUrl}
        preview
      />
    </div>
  );
};

export default ImageComponent;
