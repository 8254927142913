import React, { useState, useRef, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import {useNavigate} from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; // Import default theme
import 'primereact/resources/primereact.min.css'; // Core CSS
import 'primeicons/primeicons.css'; // Icons
import { loginUser } from 'services/AuthService';
import { saveToken } from 'utils/tokenUtils';
import { Toast } from 'primereact/toast'; // Toast component
import { showSuccessToast,  showErrorToast } from 'utils/validationUtils';
import  checkAuthentication from 'utils/clientUtils';
// Custom styles for crimson red theme
import './Login.css';

const Login = () => {

  // ... (rest of your component logic)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const toast = useRef(null); // Toast reference to show messages
  const navigate = useNavigate();
//   useEffect(() => {
//     const fetchAuthStatus = async () => {
//         const isAuth = await checkAuthentication();
//         if (isAuth) {
//           navigate('/dashboard');
//         }
//     };
    
//     fetchAuthStatus();
// }, []);


  const handleLogin = async (e) => {
    e.preventDefault();
    // Handle login logic here
    const userCredentials = { username, password };
    const loginResult = await loginUser(userCredentials);

    if (loginResult.success) {
      // redirect to dashboard page
      saveToken(loginResult.token);
      // showSuccessToast(toast, 'Login Successful', loginResult.message);
      navigate('/dashboard')

    } else {
      // Show error message
      showErrorToast(toast, 'Login Failed', loginResult.message);
    }

  };


  return (
    <div className="login-container">
      <Toast ref={toast} />
      <div className="login-panel">
        <h2 className="login-title">Dropsell</h2>
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="username">Phone number / Email</label>
            <InputText id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div className="p-field">
            <label htmlFor="password">Password</label>
            <Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} feedback={false} toggleMask />
          </div>
          <Button label="Login" onClick = {(e) => {handleLogin(e)}} />
        </div>
      </div>
    </div>
  );
};

export default Login;
