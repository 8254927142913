import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; // Make sure to import Link
import { Outlet } from 'react-router-dom'; // Make sure to import Outlet
import checkAuthentication from 'utils/clientUtils';
import './Dashboard.css';
import Cart from 'components/cart/Cart';

const Dashboard = () => {
    const location = useLocation(); // Get the current location
    const [activeIndex, setActiveIndex] = useState(0);
    const [isCartVisible, setIsCartVisible] = useState(false);
    const navigate = useNavigate();

    const viewUserCart = () => {
        setIsCartVisible(true);
    }

    const handleCloseCart = () => {
        setIsCartVisible(false);
    }

    const handleSearch = () => {
        let searchString = document.getElementById("dashboard_search_input").value
        if(searchString === "") {
            return
        }
        navigate(`/productsearch/${searchString}`)
    }

    const items = [
        {label: 'Home', icon: 'pi pi-fw pi-home', to: '/dashboard/'},
        {label: 'Shop', icon: 'pi pi-fw pi-shopping-cart', to: '/dashboard/shop'},
        {label: 'Orders', icon: 'pi pi-fw pi-shopping-cart', to: '/dashboard/orders'},
        {label: 'Account', icon: 'pi pi-fw pi-user', to: '/dashboard/'}
    ];

    useEffect(() => {
        const foundIndex = items.findIndex(item => location.pathname === item.to);
        if (foundIndex !== -1) {
            setActiveIndex(foundIndex);
        }
    }, [location]);

    return (
        <div className='app_container'>
        <div className="dashboard">
            <div className='dashboard_header'>
                <div className="dashboard_header_controls container">
                    <div className='dashboard_header_title'></div>
                    <div className='dashboard_search_controls'>
                        <input className='dashboard_search_input' autoComplete='off' id="dashboard_search_input" onKeyDown={(event)=>{if(event.key==="Enter") {handleSearch()}}} type="text" placeholder="Search" />
                        <button className='dashboard_search_button' onClick={()=>{handleSearch()}}><i className="pi pi-search"></i></button>
                    </div>
                    <div className='dashboard_cart_controls'>
                        <button className='dashboard_cart_button' onClick={()=>{viewUserCart()}}><i className="pi pi-shopping-cart"></i></button>
                    </div>
                </div>
            </div>
            <div className="content">
                <Outlet/>
            </div>
            <div className="dashboard_navbar">
                    <ul className="custom-tab-menu">
                        {items.map((item, index) => (
                            <li key={item.label} className={activeIndex === index ? 'active-tab' : ''}>
                                <Link to={item.to} onClick={() => setActiveIndex(index)}>
                                    <i className={item.icon}></i>
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
            </div>

        </div>
        
        {isCartVisible && <div className="dashboard_cart">
            <Cart className="dashboard_cart" handleCloseCart={handleCloseCart}/>
        </div>}

        </div>
    );
};

export default Dashboard;
