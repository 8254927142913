

const formatNumberWithTwoDecimals = (num) => {
    return num.toFixed(2);
}

const formatNumberToCurrency = (num) => {
    if (isNaN(num)) {
        return '₱0.00';
    }
    return `₱${num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}

function formatDate(dateInput) {
    let date;
    if (typeof dateInput === 'string') {
        date = new Date(dateInput);
    } else if (dateInput instanceof Date) {
        date = dateInput;
    } else {
        throw new TypeError('Invalid date format');
    }

    const options = { month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}


export { formatNumberWithTwoDecimals, formatNumberToCurrency, formatDate }